import * as React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="policy-wrapper">
      <div className="policy-inner">
        <h2 className="policy-subtitle">Actsafe Privacy Policy</h2>
        <p className="para">
          Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
        </p>
        <p className="para">
          Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
