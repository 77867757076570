import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Dropzone } from "components/molecules";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 17,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ReplaceFormDialog = (props) => {
  const { open, handleClose, onChangeFile, formData, handleFileSubmit } = props;

  return (
    <div className="confirmation-dialog">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Replace Form
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className="dialog-description">
            <div className="upload-form">
              <Dropzone onChangeFile={onChangeFile} />
            </div>
            {formData?.errors?.uploadedFileUrl && (
              <span className="error-msg">
                {formData?.errors?.uploadedFileUrl}
              </span>
            )}
            <div className="file-name">{formData?.pdf?.name}</div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFileSubmit}
          >
            Replace
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ReplaceFormDialog;
