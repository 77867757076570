import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { customTextFieldValidator } from "utils/validations";
import { connect } from "react-redux";
import { editActiveForm } from "store/actions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 17,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditFormDialog = (props) => {
  const {
    open,
    setOpen,
    handleFilterSubmit,
    editForm,
    editActiveForm,
    setEditForm,
  } = props;
  const [formData, setFormData] = React.useState({
    title: "",
    description: "",
    errors: {},
  });

  React.useEffect(() => {
    if (editForm) {
      let obj = {
        title: editForm.title,
        description: editForm.description,
        errors: {},
      };
      setFormData(obj);
    }
  }, [editForm]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setEditForm(null);
    setFormData({
      title: "",
      description: "",
      errors: {},
    });
    setOpen(false);
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = {
      title: formData.title.trim(),
      description: formData.description.trim(),
      _id: editForm?._id,
    };
    editActiveForm(data).then((res) => {
      if (res) {
        handleFilterSubmit();
        handleClose();
      }
    });
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "title") {
        err = customTextFieldValidator({
          min: [1, "Min. length is 1 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter title !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter proper title !",
            /^[a-z0-9&.'-_()?:,;& ]+$/i,
          ],
        });
      }
      if (key === "description") {
        err = customTextFieldValidator({
          min: [1, "Min. length is 1 characters !"],
          max: [150, "Max. length is 150 characters !"],
          val: val,
          req: [true, "Please enter form description!"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter proper description !",
            /^[a-z0-9&.'-_()?:,;& ]+$/i,
          ],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.description = customTextFieldValidator({
        min: [1, "Min. length is 1 characters !"],
        max: [150, "Max. length is 150 characters !"],
        val: newFormState.description,
        req: [true, "Please enter form description!"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter proper description !",
          /^[a-z0-9&.'-_()?:,;& ]+$/i,
        ],
      });

      newFormState.errors.title = customTextFieldValidator({
        min: [1, "Min. length is 1 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.title,
        req: [true, "Please enter title !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter proper title !",
          /^[a-z0-9&.'-_()?:,;& ]+$/i,
        ],
      });

      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="confirmation-dialog">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Edit Form
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className="dialog-description" mb={1.5}>
            <label className="form-label">Title</label>
            <TextField
              variant="outlined"
              placeholder="Type in title..."
              value={formData?.title}
              onChange={onChangeForm("title")}
            />
            {formData?.errors?.title && (
              <span className="error-msg">{formData?.errors?.title}</span>
            )}
          </Typography>
          <Typography className="dialog-description" mb={1.5}>
            <div className="description-field">
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder="Type in some description..."
                value={formData?.description}
                onChange={onChangeForm("description")}
              />
              <p className="count-number">
                <span>
                  {formData?.description ? formData?.description.length : 0}
                </span>
                /150
              </p>
            </div>
            {formData?.errors?.description && (
              <span className="error-msg">{formData?.errors?.description}</span>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
const mapDispatch = (dispatch) => ({
  editActiveForm: (data) => dispatch(editActiveForm(data)),
});
export default connect(null, mapDispatch)(EditFormDialog);
