import React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import Logo from "assets/images/logo.png";
import Button from "@mui/material/Button";
import { Confirmation } from "components/molecules";
import "./index.css";
import {
  loginUser_,
  logoutUser,
  setInitialMenu_,
  toggleMenu_,
} from "store/actions";
import { connect } from "react-redux";
import Logout from "assets/images/logout.png";

const LeftSidebar = (props) => {
  const { loginUserFn, logoutUser, setInitialMenu_, menuInfo, toggleMenu_ } =
    props;
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuToggle = (key, val = undefined) => {
    toggleMenu_({ key, val });
  };

  const handleClickYes = () => {
    setOpen(false);
    setInitialMenu_();
    loginUserFn(null);
    logoutUser().then((res) => {
      if (res) {
        localStorage.clear();
        history.push("/");
      }
    });
  };

  return (
    <>
      <aside className="sidebar">
        <div className="sidebar-inner">
          <div className="sidebar-top">
            <div
              className="sidebar-logo"
              onClick={() => {
                handleMenuToggle("activeForms", true);
              }}
            >
              <figure>
                <Link to="/active-forms">
                  <img src={Logo} alt="Logo" />
                </Link>
              </figure>
            </div>
            <ul className="sidebar-menu">
              {/* <li
                className="parent-menu drpdowm-arrow"
                key="3"
                onClick={() => {
                  handleMenuToggle("addNewForm");
                }}
              >
                <Link
                  to="/add-form"
                  className={`arrow add-form ${
                    menuInfo?.addNewForm ? "active" : ""
                  }`}
                >
                  <span>Add New Form</span>
                </Link>
              </li> */}
              <li
                key="1"
                onClick={() => {
                  handleMenuToggle("activeForms");
                }}
              >
                <Link
                  to="/active-forms"
                  className={`arrow ${menuInfo?.activeForms ? "active" : ""}`}
                >
                  <span>Active Forms</span>
                </Link>
              </li>

              <li
                key="2"
                onClick={() => {
                  handleMenuToggle("archivedForms");
                }}
              >
                <Link
                  to="/archived-forms"
                  className={`archive-form ${
                    menuInfo?.archivedForms ? "active" : ""
                  }`}
                >
                  <span>Archived Forms</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sidebar-bottom">
            <div className="log-out">
              <Button className="logout" onClick={handleClickOpen}>
                <figure>
                  <img src={Logout} alt="Icon" />
                </figure>
                <span>Log out</span>
              </Button>
            </div>
            <Confirmation
              title="Logout"
              description="Are you sure you want to logout from admin panel?"
              open={open}
              handleClose={handleClose}
              handleClickYes={handleClickYes}
            />
          </div>
        </div>
      </aside>
    </>
  );
};

const mapState = (state) => ({
  menuInfo: state.sideMenuInfo,
  menuLevel2: state.sideMenuInfo.menuLevel2,
});
const mapDispatch = (dispatch) => ({
  loginUserFn: (data) => dispatch(loginUser_(data)),
  logoutUser: () => dispatch(logoutUser()),
  setInitialMenu_: () => dispatch(setInitialMenu_()),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});

export default withRouter(connect(mapState, mapDispatch)(LeftSidebar));
