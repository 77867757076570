import React, { Component } from "react";
import { LeftSidebar, HeaderSection, Dropzone } from "components/molecules";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";

class AddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }
  handleChange(files) {
    this.setState({
      files: files,
    });
  }
  render() {
    return (
      <div className="page-wrapper">
        <div className="main-wrapper">
          <LeftSidebar />
          <HeaderSection />
          <div className="main-section">
            <div className="main-section-inner">
              <div className="breadcrumb-wrap">
                <div className="breadcrumb-left">
                  <h1 className="title">Add New Form</h1>
                </div>
              </div>
              <div className="add-form-row">
                <div className="add-form-col">
                  <div className="group-form spacing">
                    <div className="add-form-field">
                      <label className="form-label">Title</label>
                      <TextField
                        label="Title"
                        variant="outlined"
                        placeholder="Type in title..."
                      />
                      {/* <span className="error-msg">Static Content Error</span> */}
                    </div>
                    <div className="add-form-field">
                      <label className="form-label">
                        Description (Optional)
                      </label>
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                        defaultValue="Type in some description..."
                        placeholder="Type in some description..."
                      />
                      {/* <span className="error-msg">Static Content Error</span> */}
                    </div>
                    <div className="add-form-field">
                      {/* <div className="upload-form-field">
                        <div className="upload-form height">
                          <input type="file" name="UploadImage" />
                          <div className="upload-icon">
                            <div className="icon">
                              <UploadFileIcon />
                            </div>
                            <p>Click to browse or drag and drop your form</p> */}
                      <Dropzone />
                      {/* </div>
                        </div>
                      </div> */}
                      <div className="file-name">
                        {/* <p>Upload File</p> */}
                      </div>
                    </div>
                    {/* <DropzoneArea onChange={this.handleChange.bind(this)} /> */}
                    <div className="btn-section">
                      <Button variant="contained" color="primary">
                        Upload Form
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddForm;
