export const convertObjToStringParams = (obj) => {
  const mappedKeys = Object.keys(obj).map((k) => {
    return `${k}=${obj[k]}`;
  });

  return mappedKeys.join("&");
};

export const getTimeOffset = () => {
  const d = new Date();
  let diff = d.getTimezoneOffset();
  return diff;
};
