import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import "./index.css";
import { Loader } from "components/atoms";
import {
  getUserDetail,
  updateUser,
  updatePassword,
  setInitialMenu_,
} from "store/actions";
import { connect } from "react-redux";
import {
  customTextFieldValidator,
  passwordFieldValidator,
} from "utils/validations";

const UserProfile = (props) => {
  const {
    showLoader,
    getUserDetail,
    updateUser,
    updatePassword,
    setInitialMenu_,
  } = props;
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    errors: {},
  });

  const [formData2, setFormData2] = React.useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    errors: {},
  });

  const [fieldType, setFieldType] = React.useState({
    oldPassword: "password",
    password: "password",
    confirmPassword: "password",
  });

  React.useEffect(() => {
    fetchDetail();
    setInitialMenu_("remove");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleFieldType = (key) => {
    const newFieldType = { ...fieldType };
    if (newFieldType[key] === "password") {
      newFieldType[key] = "text";
    } else {
      newFieldType[key] = "password";
    }
    setFieldType(newFieldType);
  };

  const onChangeForm2 = (key) => (e) => {
    const newFormData = { ...formData2 };

    checkForError2(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData2(newFormData);
  };

  const handleSubmit2 = () => {
    if (checkForError2()) return false;
    const data = {
      oldPassword: formData2.oldPassword,
      password: formData2.password,
      confirmPassword: formData2.confirmPassword,
    };
    updatePassword(data).then((res) => {
      if (res) {
        setFormData2({
          oldPassword: "",
          password: "",
          confirmPassword: "",
          errors: {},
        });
      }
    });
  };

  const checkForError2 = (key = "", val = "") => {
    const newFormState = { ...formData2 };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key.includes("word")) {
        err = passwordFieldValidator(8, 40, val, true);
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.password = passwordFieldValidator(
        8,
        40,
        newFormState.password,
        true,
        true
      );
      newFormState.errors.oldPassword = passwordFieldValidator(
        8,
        40,
        newFormState.oldPassword,
        true
      );
      newFormState.errors.confirmPassword = passwordFieldValidator(
        8,
        40,
        newFormState.confirmPassword,
        true,
        true
      );
      if (newFormState.errors.confirmPassword) {
        newFormState.errors.confirmPassword =
          "Please enter same password as above";
      }
      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData2(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const fetchDetail = () => {
    getUserDetail().then((resp) => {
      let obj = {
        firstName: resp?.firstName,
        lastName: resp?.lastName,
        email: resp.email,
        errors: {},
      };
      setFormData(obj);
    });
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        newFormState.errors[key] = "";
      }
      if (key === "lastName") {
        err = customTextFieldValidator({
          min: [1, "Min. length is 1 characters !"],
          max: [25, "Max. length is 25 characters !"],
          val: val,
          req: [true, "Please enter last name !"],
          onlyAlpha: [true, "Please enter only alphabets !"],
        });
      }
      if (key === "firstName") {
        err = customTextFieldValidator({
          min: [1, "Min. length is 1 characters !"],
          max: [25, "Max. length is 25 characters !"],
          val: val,
          req: [true, "Please enter first name !"],
          onlyAlpha: [true, "Please enter only alphabets !"],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.lastName = customTextFieldValidator({
        min: [1, "Min. length is 1 characters !"],
        max: [25, "Max. length is 25 characters !"],
        val: newFormState?.lastName,
        req: [true, "Please enter last name !"],
        onlyAlpha: [true, "Please enter only alphabets !"],
      });

      newFormState.errors.firstName = customTextFieldValidator({
        min: [1, "Min. length is 1 characters !"],
        max: [25, "Max. length is 25 characters !"],
        val: newFormState?.firstName,
        req: [true, "Please enter first name !"],
        onlyAlpha: [true, "Please enter only alphabets !"],
      });

      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
    };
    updateUser(data).then((res) => {
      if (res) {
        fetchDetail();
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <LeftSidebar />
        <HeaderSection />
        <div className="main-section">
          {showLoader && <Loader />}

          <div className="main-section-inner">
            <div className="profile-detail-section">
              <div className="breadcrumb-wrap">
                <div className="breadcrumb-left">
                  <h1 className="title">Profile Detail</h1>
                </div>
              </div>
              <div className="form-detail-inner">
                <div className="add-form-field">
                  <label className="form-label">First Name</label>
                  <TextField
                    variant="outlined"
                    placeholder="Type Name"
                    value={formData?.firstName}
                    onChange={onChangeForm("firstName")}
                  />
                  {formData?.errors?.firstName && (
                    <span className="error-msg">
                      {formData?.errors?.firstName}
                    </span>
                  )}
                </div>
                <div className="add-form-field">
                  <label className="form-label">Last Name</label>
                  <TextField
                    variant="outlined"
                    placeholder="Type Name"
                    value={formData?.lastName}
                    onChange={onChangeForm("lastName")}
                  />
                  {formData?.errors?.lastName && (
                    <span className="error-msg">
                      {formData?.errors?.lastName}
                    </span>
                  )}
                </div>
                <div className="add-form-field spacing">
                  <label className="form-label">Email</label>
                  <TextField
                    variant="outlined"
                    placeholder="Type Email"
                    value={formData?.email}
                    disabled
                  />
                  {formData?.errors?.email && (
                    <span className="error-msg">{formData?.errors?.email}</span>
                  )}
                </div>
                <div className="profile-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className="change-password-section">
              <div className="breadcrumb-wrap">
                <div className="breadcrumb-left">
                  <h1 className="title">Change Password</h1>
                </div>
              </div>
              <div className="form-detail-inner">
                <div className="add-form-field">
                  <label className="form-label">Old Password</label>
                  <div className="form-group">
                    <TextField
                      type={fieldType.oldPassword}
                      label="Old Password"
                      variant="outlined"
                      placeholder="Old Password"
                      onChange={onChangeForm2("oldPassword")}
                      value={formData2.oldPassword}
                    />
                    <div
                      className="eye-icon"
                      onClick={() => toggleFieldType("oldPassword")}
                    >
                      <IconButton>
                        {fieldType.oldPassword === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  {formData2?.errors?.oldPassword && (
                    <span className="error-msg">
                      {formData2?.errors?.oldPassword}
                    </span>
                  )}
                </div>
                <div className="add-form-field">
                  <label className="form-label">New Password</label>
                  <div className="form-group">
                    <TextField
                      type={fieldType.password}
                      label="New Password"
                      variant="outlined"
                      placeholder="New Password"
                      onChange={onChangeForm2("password")}
                      value={formData2.password}
                    />
                    <div
                      className="eye-icon"
                      onClick={() => toggleFieldType("password")}
                    >
                      <IconButton>
                        {fieldType.password === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  {formData2?.errors?.password && (
                    <span className="error-msg">
                      {formData2?.errors?.password}
                    </span>
                  )}
                </div>
                <div className="add-form-field">
                  <label className="form-label">Confirm Password</label>
                  <div className="form-group">
                    <TextField
                      type={fieldType.confirmPassword}
                      label="Confirm Password"
                      variant="outlined"
                      placeholder="Confirm Password"
                      onChange={onChangeForm2("confirmPassword")}
                      value={formData2.confirmPassword}
                    />
                    <div
                      className="eye-icon"
                      onClick={() => toggleFieldType("confirmPassword")}
                    >
                      <IconButton>
                        {fieldType.confirmPassword === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  {formData2?.errors?.confirmPassword && (
                    <span className="error-msg">
                      {formData2?.errors?.confirmPassword}
                    </span>
                  )}
                </div>
                <div className="profile-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit2}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getUserDetail: () => dispatch(getUserDetail()),
  updateUser: (data) => dispatch(updateUser(data)),
  updatePassword: (data) => dispatch(updatePassword(data)),
  setInitialMenu_: (data) => dispatch(setInitialMenu_(data)),
});

export default connect(mapState, mapDispatch)(UserProfile);
