import {
  USER_LOGIN,
  SET_INITIAL_AUTH_NEW,
  SET_IP,
  USER_DETAIL,
} from "store/types";

const initialState = {
  auth: null,
  error: null,
  ip: "",
  my_detail: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_AUTH_NEW:
      localStorage.clear();
      state.auth = null;
      state.error = null;
      state.ip = "";
      return { ...state };

    case USER_LOGIN:
      state.auth = action.payload;
      return { ...state };

    case USER_DETAIL:
      state.my_detail = action.payload;
      return { ...state };

    case SET_IP:
      state.ip = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default authReducer;
