import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PublicRoute extends Component {
  render() {
    const { component: Component, restricted, ...rest } = this.props;
    let isLoggedIn = localStorage.getItem("token") ? true : false;

    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn && restricted ? (
            <Redirect to="/active-forms" />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
}
PublicRoute.propTypes = {
  component: PropTypes.any,
  isLoggedIn: PropTypes.any,
  restricted: PropTypes.any,
};
const mapState = (state) => {
  return {
    isLoggedIn: state?.authInfo?.auth?.token || false,
  };
};

export default connect(mapState, null)(PublicRoute);
