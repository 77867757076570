import React from "react";
import Logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const ResetPassword = () => {
  return (
    <div className="account-wrapper">
      <div className="account-inner">
        <div className="login-section">
          <div className="logo">
            <Link to="/">
              <figure>
                <img src={Logo} alt="Logo" />
              </figure>
            </Link>
          </div>
          <h1 className="page-title">Reset Password</h1>
          <p className="page-description">Enter your new password to change</p>
          <div className="form-wrapper">
            <form autoComplete="off">
              <div className="input-row">
                <div className="form-group">
                  <TextField
                    type="password"
                    id="new-password"
                    label="New Password"
                    variant="outlined"
                  />
                  <div className="eye-icon">
                    <IconButton>
                      <VisibilityOff />
                      {/* <Visibility /> */}
                    </IconButton>
                  </div>
                </div>
                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="input-row">
                <div className="form-group">
                  <TextField
                    type="password"
                    id="confirm-password"
                    label="Confirm Password"
                    variant="outlined"
                  />
                  <div className="eye-icon">
                    <IconButton>
                      <VisibilityOff />
                      {/* <Visibility /> */}
                    </IconButton>
                  </div>
                </div>
                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="btn-wrap login-btn">
                <Button variant="contained" type="submit">
                  Set New Password
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
