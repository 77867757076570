import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadNew from "assets/images/upload-2.png";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  width: "100%",
  height: "150px",
  borderWidth: 1,
  borderRadius: 4,
  borderColor: "rgba(105, 106, 109, 0.3)",
  borderStyle: "dashed",
  backgroundColor: "#fff",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "blue",
};

const acceptStyle = {
  borderColor: "green",
};

const rejectStyle = {
  borderColor: "red",
};

function DropzoneComponent(props) {
  const { onChangeFile } = props;
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (files.length > 0) {
      onChangeFile(files);
    }
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  //const thumbs = files.map((file) => <div key={file.name}>{file.name}</div>);

  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className="upload-icon">
          <div className="icon">
            <figure>
              <img src={UploadNew} alt="Icon" />
            </figure>
          </div>
          <div className="title">
            Click to browse or drag and drop your form
          </div>
        </div>
      </div>
      {/* <aside>{thumbs}</aside> */}
    </section>
  );
}

export default DropzoneComponent;
