import authReducer from "./authReducer";
import toastReducer from "./toastReducer";
import loaderReducer from "./loaderReducer";
import sideMenuReducer from "./sideMenuReducer";
import formReducer from "./formReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  authInfo: authReducer,
  toastInfo: toastReducer,
  loaderInfo: loaderReducer,
  sideMenuInfo: sideMenuReducer,
  formInfo: formReducer,
});

export default rootReducer;
