import Alert from "assets/images/empty-alert.png";
import "./index.css";

const EmptyAlertDialog = (props) => {
  const { description } = props;
  return (
    <div className="empty-alert-box">
      <div className="alert-icon">
        <figure>
          <img src={Alert} alt="Icon" />
        </figure>
      </div>
      <p className="alert-description">{description}</p>
    </div>
  );
};

export default EmptyAlertDialog;
