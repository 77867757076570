import "./App.css";
import Routes from "./routes";
import { NotificationBar } from "components/atoms";

function App(props) {
  return (
    <div className="App">
      <Routes />
      <NotificationBar />
    </div>
  );
}

export default App;
