import { TOGGLE_MENU, SET_INITIAL } from "store/types";

export const toggleMenu_ = (payload) => ({
  type: TOGGLE_MENU,
  payload,
});

export const setInitialMenu_ = (payload = "") => ({
  type: SET_INITIAL,
  payload,
});
