import React, { useState } from "react";
import Logo from "assets/images/logo.png";
import { Link, withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.css";
import { passwordFieldValidator, emailFieldValidator } from "utils/validations";
import { connect } from "react-redux";
import { loginUser } from "store/actions";
import { Loader } from "components/atoms";

const Login = (props) => {
  const { loginUser, showLoader } = props;
  const [fieldType, setFieldType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    errors: {},
  });

  const toggleFieldType = () => {
    if (fieldType === "password") {
      setFieldType("text");
    } else {
      setFieldType("password");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkForError()) return false;
    const data = {
      email: formData.email.toLowerCase(),
      password: formData.password,
    };
    loginUser(data).then((res) => {
      if (res) onCloseForm();
    });
  };

  const onCloseForm = () => {
    setFormData({
      email: "",
      password: "",
      errors: {},
    });
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "password") {
        err = passwordFieldValidator(8, 40, val, true);
      } else if (key === "email") {
        err = emailFieldValidator(val, true);
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.password = passwordFieldValidator(
        8,
        40,
        newFormState.password,
        true
      );
      newFormState.errors.email = emailFieldValidator(newFormState.email, true);
      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="account-wrapper">
      <div className="account-inner">
        {showLoader && <Loader />}
        <div className="login-section">
          <div className="logo">
            <Link to="/">
              <figure>
                <img src={Logo} alt="Logo" />
              </figure>
            </Link>
          </div>
          <h1 className="page-title">Hi There!</h1>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="input-row">
                <TextField
                  type="text"
                  id="email"
                  label="Email"
                  variant="outlined"
                  onChange={onChangeForm("email")}
                  value={formData.email}
                  className={formData?.errors?.email ? "input-error" : ""}
                />
                {formData?.errors?.email && (
                  <span className="error-msg">{formData?.errors?.email}</span>
                )}
              </div>
              <div className="password-row">
                <div className="form-group">
                  <TextField
                    type={fieldType}
                    id="password"
                    label="Password"
                    variant="outlined"
                    onChange={onChangeForm("password")}
                    value={formData.password}
                    className={formData?.errors?.password ? "input-error" : ""}
                  />
                  <div className="eye-icon" onClick={toggleFieldType}>
                    <IconButton aria-label="toggle password visibility">
                      {fieldType === "password" ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </div>
                </div>
                {formData?.errors?.password && (
                  <span className="error-msg">
                    {formData?.errors?.password}
                  </span>
                )}
              </div>
              <div className="forgot-link" hidden>
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              <div className="btn-wrap login-btn">
                <Button variant="contained" type="submit">
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
});
const mapDispatch = (dispatch) => ({
  loginUser: (data) => dispatch(loginUser(data)),
});
export default withRouter(connect(mapState, mapDispatch)(Login));
