import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  EmptyAlertDialog,
  UploadFormDialog,
  DeleteGroupDialog,
  EditFormDialog,
} from "components/molecules";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Delete from "assets/images/delete.png";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import "./index.css";
import { Loader } from "components/atoms";
import { connect } from "react-redux";
import { getActiveForms } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import Edit from "assets/images/edit.png";
import UploadWhite from "assets/images/upload-white.png";
import Search from "assets/images/search-icon.png";

const ActiveForms = (props) => {
  const { showLoader, getActiveForms } = props;
  const [listData, setListData] = React.useState([]);
  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    error: "",
  });
  const [desc, setDesc] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [openDelete, setDeleteOpen] = React.useState(false);

  const [openEdit, setEditOpen] = React.useState(false);
  const [editForm, setEditForm] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteClickOpen = (formInfo) => {
    setEditForm(formInfo);
    setDeleteOpen(true);
  };

  const handleEditClickOpen = (formInfo) => {
    setEditOpen(true);
    setEditForm({ ...formInfo });
  };

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApiData = () => {
    let apiData = {};

    if (filter.search) {
      apiData.search = filter.search;
    }
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getActiveForms({ params }).then((resp) => {
      if (filter.search === "" && resp.length === 0) {
        setDesc(`You haven’t have any form yet. To add a new form to selected group,
        click on the “Upload form” button above and start adding.`);
      }
      setListData(resp);
    });
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;
    setFilter(newFilter);
    if (key === "search") {
      setTimeout(() => {
        setCounter((pc) => pc + 1);
      }, 800);
    }
  };

  const getDescription = () => {
    if (showLoader) {
      return "Loading ...";
    }
    if (filter.search && listData.length === 0) return "No data";
    return desc;
  };

  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <LeftSidebar />
        <HeaderSection />
        <div className="main-section">
          {showLoader && <Loader />}

          <div className="main-section-inner active-form">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Active Forms</h1>
              </div>
              <div className="breadcrumb-right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Upload Form
                  <span className="upload-white-icon-img">
                    <figure>
                      <img src={UploadWhite} alt="Icon" />
                    </figure>
                  </span>
                </Button>
              </div>
            </div>
            <div className="search-field">
              <TextField
                variant="outlined"
                placeholder="Search by form name..."
                onChange={handleChangeFilter("search")}
                value={filter?.search}
              />
              {/* <span className="error-msg">Static Content Error</span> */}
              <div className="search-icon">
                <figure>
                  <img src={Search} alt="Icon" />
                </figure>
              </div>
            </div>
            <div className="active-form-wrap">
              {listData.length > 0 ? (
                listData.map((category, i) => {
                  return (
                    <div className="form-content" key={category._id + i}>
                      <h2 className="form-subtitle">
                        {category._id.toUpperCase()}
                      </h2>
                      {category.formsList.map((fo, c) => {
                        return (
                          <div className="form-inner" key={fo._id}>
                            <div className="group-names form-spacing">
                              <div className="group-names-left">
                                <Link
                                  to={`/active-forms/${fo._id}`}
                                  className="group-subtitle"
                                >
                                  {fo.title}
                                </Link>
                              </div>
                              <div className="action-wrap">
                                <Tooltip title="Archive">
                                  <IconButton
                                    color="primary"
                                    onClick={() => handleDeleteClickOpen(fo)}
                                  >
                                    <figure>
                                      <img src={Delete} alt="Icon" />
                                    </figure>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleEditClickOpen(fo)}
                                  >
                                    <figure>
                                      <img src={Edit} alt="Icon" />
                                    </figure>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                            <p className="title-description">
                              {fo?.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="empty-form-alert">
                  <EmptyAlertDialog description={getDescription()} />
                </div>
              )}
            </div>
            <div className="upload-form-popup">
              <UploadFormDialog
                open={open}
                setOpen={setOpen}
                handleFilterSubmit={handleFilterSubmit}
              />
            </div>
            <div className="delete-alert">
              <DeleteGroupDialog
                open={openDelete}
                handleFilterSubmit={handleFilterSubmit}
                setOpen={setDeleteOpen}
                formInfo={editForm}
              />
            </div>
            <div className="edit-alert">
              <EditFormDialog
                open={openEdit}
                setOpen={setEditOpen}
                handleFilterSubmit={handleFilterSubmit}
                editForm={editForm}
                setEditForm={setEditForm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getActiveForms: (data) => dispatch(getActiveForms(data)),
});

export default connect(mapState, mapDispatch)(ActiveForms);
