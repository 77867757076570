import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast_ } from "store/actions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationBar = (props) => {
  const { toast, toastFn } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (toast === null) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [toast]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    toastFn(null);
  };

  if (!toast) return null;

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={7000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={toast?.type}
          sx={{ width: "100%" }}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

NotificationBar.propTypes = {
  toast: PropTypes.any,
  toastFn: PropTypes.func,
};

const mapState = (state) => {
  return {
    toast: state?.toastInfo?.toast,
  };
};

const mapDispatch = (dispatch) => ({
  toastFn: (data) => dispatch(toast_(data)),
});

export default connect(mapState, mapDispatch)(NotificationBar);
