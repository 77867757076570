import { callApi } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import { getTimeOffset } from "utils/helper";
import { GET_FORM_DETAIL } from "store/types";

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const addForm = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/form",
      method: "POST",
      body: data,
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({ type: "success", message: "Form uploaded successfully !" })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getActiveForms = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: `/admin/form/getActiveForms?${data?.params}`,
      method: "GET",
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const editActiveForm = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/form",
      method: "PATCH",
      body: data,
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({ type: "success", message: "Form updated successfully !" })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const archiveForm = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: `/admin/form/archiveForm?${data?.params}`,
      method: "PATCH",
      body: data,
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({ type: "success", message: "Form archived successfully !" })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getArchiveForms = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: `/admin/form/archivedFormsList?${data?.params}`,
      method: "GET",
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const restoreForm = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/form/restoreForm",
      method: "PATCH",
      body: data,
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({ type: "success", message: "Form unarchived successfully !" })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getFormDetail_ = (payload) => {
  return {
    type: GET_FORM_DETAIL,
    payload,
  };
};

export const getFormDetail = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: `/admin/form/selectedFormDetails?${data?.params}`,
      method: "GET",
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          dispatch(getFormDetail_(resData?.data));
          return true;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const editFormDetails = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/form/updateFormAttachment",
      method: "PATCH",
      body: data,
      customHeaders: {
        offset: getTimeOffset(),
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({ type: "success", message: "Form updated successfully !" })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const isFormValid = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: `/admin/form/isFormNameValid?title=${data.title}`,
      method: "GET",
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
