import { callApi } from "utils/apiCaller";
import { USER_LOGIN, USER_DETAIL } from "store/types";
import { toast_, loader_ } from "store/actions";

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const loginUser_ = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/login",
      method: "POST",
      body: data,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        if (resData?.data?.token) {
          localStorage.setItem("token", resData?.data?.token);
          dispatch(loginUser_(resData?.data));
          dispatch(toast_({ type: "success", message: "Login successfull !" }));
          return true;
        }
      } else {
        dispatch(loginUser_(null));
        localStorage.clear();
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    return callApi({ endpoint: "/admin/logout", method: "PATCH" }).then(
      (response) => {
        const status = response?.status;
        const resData = response?.data;
        if (status === 200) {
          return true;
        } else {
          handleError(dispatch, resData);
          return false;
        }
      }
    );
  };
};

export const getUserDetail_ = (payload) => {
  return {
    type: USER_DETAIL,
    payload,
  };
};

export const getUserDetail = () => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: `/admin/getuserdetail`,
      method: "GET",
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          dispatch(getUserDetail_(resData?.data));
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updateUser = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/update-user",
      method: "PATCH",
      body: data,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({ type: "success", message: "User updated successfully !" })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updatePassword = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi({
      endpoint: "/admin/change-password",
      method: "PATCH",
      body: data,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: "Password updated successfully !",
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
