import { TOGGLE_MENU, SET_INITIAL } from "store/types";

const initialState = {
  activeForms: true,
  menuLevel2: "",
};

const sideMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      Object.keys(state).forEach((k) => {
        if (k !== action.payload.key && !k.includes("menuLevel")) {
          state[k] = false;
        }
      });
      if (action.payload.val === undefined || action.payload.val === null) {
        state[action.payload.key] = !state[action.payload.key];
      } else {
        state[action.payload.key] = action.payload.val;
      }
      return { ...state };

    case SET_INITIAL:
      Object.keys(state).forEach((k) => {
        state[k] = false;
      });
      state.activeForms = action.payload ? false : true;
      state.menuLevel2 = "";
      return { ...state };

    default:
      return state;
  }
};

export default sideMenuReducer;
