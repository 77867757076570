import validator from "validator";

export const textFieldValidator = (
  min = 1,
  max = 20,
  val = "",
  req = true,
  onlyAlpha = true,
  alphaWithSpaces = false
) => {
  let error = "";
  if (!val && req) {
    error = "Please fill this field !";
  } else if (val) {
    if (val.length < min) {
      error = `Min. length is ${min} characters !`;
    } else if (val.length > max) {
      error = `Max. length is ${max} characters !`;
    } else if (onlyAlpha) {
      if (!validator.isAlpha(val)) error = "Please enter only alphabets !";
    } else if (alphaWithSpaces) {
      if (
        !validator.matches(val, /^[a-z ]+$/i) ||
        val.startsWith(" ") ||
        val.endsWith(" ")
      )
        error = "Please enter only alphabets with spaces in between !";
    }
  }
  return error;
};

export const customTextFieldValidator = ({
  min,
  max,
  val,
  req,
  onlyAlpha = [],
  alphaWithSpaces = [],
  customRegex = [],
}) => {
  let error = "";
  if (!val && req[0]) {
    error = req[1];
  } else if (val) {
    if (val.length < min[0]) {
      error = min[1];
    } else if (val.length > max[0]) {
      error = max[1];
    } else if (onlyAlpha[0]) {
      if (!validator.isAlpha(val)) error = onlyAlpha[1];
    } else if (alphaWithSpaces[0]) {
      if (
        !validator.matches(val, /^[a-z ]+$/i) ||
        val.startsWith(" ") ||
        val.endsWith(" ")
      )
        error = alphaWithSpaces[1];
    } else if (customRegex[0]) {
      if (!validator.matches(val, customRegex[2]) || val.startsWith(" "))
        error = customRegex[1];
    }
  }
  return error;
};

export const emailFieldValidator = (
  val = "",
  req = true,
  msg = "Please enter your email !"
) => {
  let error = "";
  if (!val && req) {
    error = msg;
  } else if (val) {
    if (!validator.isEmail(val)) error = "Please enter a valid email !";
  }
  return error;
};

export const passwordFieldValidator = (
  min = 8,
  max = 20,
  val = "",
  req = true,
  regex = false
) => {
  let error = "";
  if (!val && req) {
    error = "Please enter your password !";
  } else if (val) {
    if (val.length < min) {
      error = `Min. length is ${min} characters !`;
    } else if (val.length > max) {
      error = `Max. length is ${max} characters !`;
    } else if (regex) {
      let regExp =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (!regExp.test(val)) {
        error =
          "Please enter a proper password with at least one uppercase letter, one lowercase letter, one number and one special character !";
      }
    }
  }
  return error;
};

export const fileFieldValidator = ({
  min,
  max,
  fileType,
  fileSizeUnit,
  file,
  req,
  multiplier,
}) => {
  console.log("--filesizeunit", fileSizeUnit);
  let error = "";
  if (!file && req) {
    error = "Please upload a file !";
  } else if (file) {
    if (min && file.size < min * multiplier)
      error = `Please upload a file more than ${min} ${fileSizeUnit} !`;

    if (max && file.size > max * multiplier) {
      error = `Please upload a file less than ${max} ${fileSizeUnit} !`;
      if (file.size > 1000000) {
        error = `Please upload a file less than ${max / 1000} MB !`;
      }
    }

    if (fileType && !file.type.includes(fileType))
      error = `Please upload a ${fileType} file !`;
  }
  return error;
};

export const mobileNumberValidator = ({ len, val, req }) => {
  let error = "";
  if (!val && req[0]) {
    error = req[1];
  } else if (val) {
    if (val.toString().length !== len[0]) {
      error = len[1];
    }
  }
  return error;
};
