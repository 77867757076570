import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  AddForm,
  ActiveForms,
  ActiveFormsDetail,
  ActiveFormsEdit,
  ArchivedForms,
  ArchivedFormsDetail,
  UserProfile,
  PrivacyPolicy,
  TermsConditions,
} from "components/pages";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <PublicRoute restricted={true} exact path="/" component={Login} />
          <PublicRoute
            restricted={true}
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/reset-password"
            component={ResetPassword}
          />

          <PrivateRoute exact path="/add-form" component={AddForm} />

          <PrivateRoute exact path="/active-forms" component={ActiveForms} />
          <PrivateRoute
            exact
            path="/active-forms/:id/edit"
            component={ActiveFormsEdit}
          />
          <PrivateRoute
            exact
            path="/active-forms/:id"
            component={ActiveFormsDetail}
          />

          <PrivateRoute
            exact
            path="/archived-forms"
            component={ArchivedForms}
          />
          <PrivateRoute
            exact
            path="/archived-forms/:id"
            component={ArchivedFormsDetail}
          />

          <PrivateRoute exact path="/profile" component={UserProfile} />
          <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
          <PublicRoute
            exact
            path="/terms-conditions"
            component={TermsConditions}
          />
        </Switch>
      </Router> 
    </>
  );
};

export default Routes;
