import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 17,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Confirmation = (props) => {
  const {
    open,
    handleClose,
    title,
    description,
    handleClickYes,
    unarchieve,
    confirm,
    handleChangeRadio,
  } = props;

  return (
    <div className="confirmation-dialog">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className="dialog-description">
            {description}
            {unarchieve && (
              <div className="popup-radio-buttons">
                <RadioGroup
                  row
                  aria-label="edit"
                  name="confirmdelete"
                  value={confirm}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="I confirm to unarchive the form"
                  />
                </RadioGroup>
              </div>
            )}
            {/* <div className="popup-radio-buttons">
              <RadioGroup row aria-label="edit" name="confirmdelete">
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="I confirm to unarchive the form"
                />
              </RadioGroup>
            </div> */}
          </Typography>
        </DialogContent>

        <DialogActions>
          {/* <Button variant="outlined" color="error" onClick={handleClose}>
            No
          </Button> */}
          <Button variant="contained" color="primary" onClick={handleClickYes}>
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default Confirmation;
