import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  DeleteGroupDialog,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { Link, useParams, useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Upload from "assets/images/upload.png";
import { connect } from "react-redux";
import { getFormDetail } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";

const ActiveFormsDetail = (props) => {
  const { getFormDetail, showLoader, detail } = props;
  const { id } = useParams();
  const history = useHistory();

  const [openDelete, setDeleteOpen] = React.useState(false);

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { _id: id };
    const params = convertObjToStringParams(apiData);
    getFormDetail({ params });
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleFilterSubmit = () => {
    history.goBack();
  };

  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <LeftSidebar />
        <HeaderSection />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <div className="back-arrow" onClick={() => history.goBack()}>
                  <ArrowBackIosNewIcon />
                  Back
                </div>
                <h1 className="title">{detail?.title}</h1>
              </div>
              <div className="breadcrumb-right">
                <div className="btn-group">
                  <Link to={`/active-forms/${id}/edit`}>
                    <Button variant="outlined" color="primary">
                      Edit Detail
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteClickOpen}
                  >
                    Archive Form
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-detail-inner">
              <div className="add-form-field">
                <label className="form-label">Form Name</label>
                <TextField
                  variant="outlined"
                  placeholder="Type in Form Name..."
                  value={detail?.title}
                  disabled
                />
                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="add-form-field">
                <label className="form-label">Description</label>
                <div className="description-field">
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder="Type in some description..."
                    value={detail?.description}
                    disabled
                  />
                </div>

                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="form-detail-footer">
                <div className="form-text">
                  <span className="upload-icon-img">
                    <figure>
                      <img src={Upload} alt="Icon" />
                    </figure>
                  </span>
                  <a
                    href={detail?.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                    // href="#0"
                    // onClick={() => {
                    //   window.open(detail?.attachment);
                    // }}
                  >
                    {detail?.title}
                  </a>
                </div>
              </div>
            </div>
            <div className="delete-alert">
              <DeleteGroupDialog
                open={openDelete}
                handleFilterSubmit={handleFilterSubmit}
                setOpen={setDeleteOpen}
                formInfo={detail}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.formInfo?.current,
});
const mapDispatch = (dispatch) => ({
  getFormDetail: (data) => dispatch(getFormDetail(data)),
});
export default connect(mapState, mapDispatch)(ActiveFormsDetail);
