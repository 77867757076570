import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { archiveForm } from "store/actions";
import { connect } from "react-redux";
import { convertObjToStringParams } from "utils/helper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 17,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteGroupDialog = (props) => {
  const { open, setOpen, formInfo, handleFilterSubmit, archiveForm } = props;
  const [formData, setFormData] = React.useState({
    confirm: 0,
    errors: {},
  });
  const handleClose = () => {
    setFormData({
      confirm: 0,
      errors: {},
    });
    setOpen(false);
  };
  const handleChangeRadio = (e) => {
    const newFormData = { ...formData };

    newFormData.confirm = 1;
    setFormData(newFormData);
  };

  const handleSubmit = () => {
    if (formData?.confirm === 0) return false;
    const apiData = { _id: formInfo?._id };
    const params = convertObjToStringParams(apiData);
    archiveForm({ params }).then((res) => {
      if (res) {
        handleClose();
        handleFilterSubmit();
      }
    });
  };

  return (
    <div className="confirmation-dialog">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Archive Form
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className="dialog-description">
            <p className="para">
              "{formInfo?.title}" will be moved to “Archived Forms”. Users will
              be no longer have access to this form after archiving.
            </p>
            <div className="popup-radio-buttons">
              <RadioGroup
                row
                aria-label="edit"
                name="confirmdelete"
                value={formData?.confirm}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="I confirm to archive the form"
                />
              </RadioGroup>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
const mapDispatch = (dispatch) => ({
  archiveForm: (data) => dispatch(archiveForm(data)),
});
export default connect(null, mapDispatch)(DeleteGroupDialog);
