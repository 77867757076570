import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  EmptyAlertDialog,
  Confirmation,
} from "components/molecules";
import TextField from "@mui/material/TextField";
import Unarchived from "assets/images/unarchived.png";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { Loader } from "components/atoms";
import { connect } from "react-redux";
import { getArchiveForms, restoreForm } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import Search from "assets/images/search-icon.png";

const ArchivedForms = (props) => {
  const { showLoader, getArchiveForms, restoreForm } = props;
  const [listData, setListData] = React.useState([]);
  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    error: "",
    confirm: 0,
  });
  const [desc, setDesc] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [selectedForm, setSelectedForm] = React.useState(null);

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeRadio = (e) => {
    const newFormData = { ...filter };

    newFormData.confirm = 1;
    setFilter(newFormData);
  };

  const handleClickOpen = (formInfo) => {
    setSelectedForm(formInfo);
    setOpen(true);
  };

  const handleClose = () => {
    setFilter({
      confirm: 0,
    });

    setOpen(false);
  };

  const handleClickYes = () => {
    if (filter?.confirm === 0) return false;

    restoreForm({ _id: selectedForm._id }).then((resp) => {
      if (resp) {
        handleFilterSubmit();
        setOpen(false);
      }
    });
  };

  const getApiData = () => {
    let apiData = {};

    if (filter.search) {
      apiData.search = filter.search;
    }
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getArchiveForms({ params }).then((resp) => {
      if (filter.search === "" && resp.length === 0) {
        setDesc(`You haven’t have any form yet.`);
      }
      setListData(resp);
    });
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;
    setFilter(newFilter);
    if (key === "search") {
      setTimeout(() => {
        setCounter((pc) => pc + 1);
      }, 800);
    }
  };

  const getDescription = () => {
    if (showLoader) {
      return "Loading ...";
    }
    if (filter.search && listData.length === 0) return "No data";
    return desc;
  };

  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <LeftSidebar />
        <HeaderSection />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner active-form">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Archived Forms</h1>
              </div>
            </div>
            <div className="search-field width">
              <TextField
                // label="Search"
                variant="outlined"
                placeholder="Search by form name..."
                onChange={handleChangeFilter("search")}
                value={filter?.search}
              />
              {/* <span className="error-msg">Static Content Error</span> */}
              <div className="search-icon">
                <figure>
                  <img src={Search} alt="Icon" />
                </figure>
              </div>
            </div>
            <div className="archived-forms-wrap">
              {listData.length > 0 ? (
                listData.map((category, i) => {
                  return (
                    <div className="form-content" key={category._id + i}>
                      <h2 className="form-subtitle">
                        {category._id.toUpperCase()}
                      </h2>
                      {category.formsList.map((fo, c) => {
                        return (
                          <div className="form-inner" key={fo._id}>
                            <div className="group-names form-spacing">
                              <div className="group-names-left">
                                <Link
                                  to={`/archived-forms/${fo._id}`}
                                  className="group-subtitle"
                                >
                                  {fo.title}
                                </Link>
                              </div>
                              <div className="action-wrap">
                                <Tooltip title="Unarchive">
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleClickOpen(fo)}
                                  >
                                    <figure>
                                      <img src={Unarchived} alt="Icon" />
                                    </figure>
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                            <p className="title-description">
                              {fo?.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="empty-form-alert">
                  <EmptyAlertDialog description={getDescription()} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title="Unarchive Form"
        description={`${selectedForm?.title} will be moved to “Active Forms”. Users will be able to access this form after unarchiving. `}
        open={open}
        handleClose={handleClose}
        handleClickYes={handleClickYes}
        unarchieve={true}
        confirm={filter?.confirm}
        handleChangeRadio={handleChangeRadio}
      />
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getArchiveForms: (data) => dispatch(getArchiveForms(data)),
  restoreForm: (data) => dispatch(restoreForm(data)),
});

export default connect(mapState, mapDispatch)(ArchivedForms);
