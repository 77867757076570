import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  ReplaceFormDialog,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Upload from "assets/images/upload.png";
import { connect } from "react-redux";
import { getFormDetail, editFormDetails, uploadAFile } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";
import {
  customTextFieldValidator,
  fileFieldValidator,
} from "utils/validations";

const ActiveFormsEdit = (props) => {
  const { getFormDetail, showLoader, detail, editFormDetails, uploadAFile } =
    props;
  const { id } = useParams();
  const history = useHistory();

  const [formData, setFormData] = React.useState({
    title: "",
    description: "",
    pdf: null,
    uploadedFileUrl: "",
    attachment: "",
    errors: {},
  });
  const [openReplace, setReplaceOpen] = React.useState(false);

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (detail) {
      let obj = {
        title: detail.title,
        description: detail.description,
        attachment: detail.attachment,
        errors: {},
      };
      setFormData(obj);
    }
  }, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { _id: id };
    const params = convertObjToStringParams(apiData);
    getFormDetail({ params });
  };

  const handleReplaceClickOpen = () => {
    setReplaceOpen(true);
  };
  const handleReplaceClose = () => {
    const newFormData = { ...formData };
    newFormData.pdf = null;
    newFormData.uploadedFileUrl = "";
    if (newFormData.errors?.uploadedFileUrl)
      newFormData.errors.uploadedFileUrl = "";

    setFormData(newFormData);
    setReplaceOpen(false);
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "title") {
        err = customTextFieldValidator({
          min: [1, "Min. length is 1 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter title !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter proper title !",
            /^[a-z0-9&.'-_()?:,;& ]+$/i,
          ],
        });
      }
      if (key === "description") {
        err = customTextFieldValidator({
          min: [1, "Min. length is 1 characters !"],
          max: [150, "Max. length is 150 characters !"],
          val: val,
          req: [true, "Please enter form description!"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter proper description !",
            /^[a-z0-9&.'-_()?:,;& ]+$/i,
          ],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.description = customTextFieldValidator({
        min: [1, "Min. length is 1 characters !"],
        max: [150, "Max. length is 150 characters !"],
        val: newFormState.description,
        req: [true, "Please enter form description!"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter proper description !",
          /^[a-z0-9&.'-_()?:,;& ]+$/i,
        ],
      });

      newFormState.errors.title = customTextFieldValidator({
        min: [1, "Min. length is 1 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.title,
        req: [true, "Please enter title !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter proper title !",
          /^[a-z0-9&.'-_()?:,;& ]+$/i,
        ],
      });

      if (val === "checkFile") {
        newFormState.errors.uploadedFileUrl = fileFieldValidator({
          min: 1,
          max: 5000,
          fileType: "application/pdf",
          fileSizeUnit: "kB",
          multiplier: 1000,
          file: newFormState.pdf,
          req: true,
        });
      }

      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setFormData({
      title: "",
      description: "",
      pdf: null,
      uploadedFileUrl: "",
      attachment: "",
      errors: {},
    });
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = {
      title: formData.title.trim(),
      description: formData.description.trim(),
      attachment: formData.attachment,
      _id: detail?._id,
    };
    editFormDetails(data).then((res) => {
      if (res) {
        handleClose();
        history.goBack();
      }
    });
  };

  const handleFileSubmit = () => {
    if (checkForError("", "checkFile")) return false;
    const fileData = new FormData();
    fileData.append("formFile", formData.pdf);

    uploadAFile(fileData).then((res) => {
      if (res?.fileS3URL) {
        formData.attachment = res?.fileS3URL;
        setFormData(formData);
        handleReplaceClose();
      }
    });
  };

  const onChangeFile = (files) => {
    const newFormData = { ...formData };

    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    let uploadedFileUrl = "";
    let uploadedFile = files[0];

    if (uploadedFile) {
      uploadedFileUrl = URL.createObjectURL(files[0]);
      newFormData.pdf = files[0];
      newFormData.uploadedFileUrl = uploadedFileUrl;

      newFormData.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 5000,
        fileType: "application/pdf",
        fileSizeUnit: "kB",
        multiplier: 1000,
        file: newFormData.pdf,
        req: true,
      });
    } else {
      newFormData.pdf = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl = "Please upload a pdf file !";
    }
    setFormData(newFormData);
  };

  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <LeftSidebar />
        <HeaderSection />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <div className="back-arrow" onClick={() => history.goBack()}>
                  <ArrowBackIosNewIcon />
                  Back
                </div>
                <h1 className="title">{detail?.title}</h1>
              </div>
              <div className="breadcrumb-right">
                <div className="btn-group">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-detail-inner">
              <div className="add-form-field">
                <label className="form-label">Form Name</label>
                <TextField
                  variant="outlined"
                  placeholder="Type in Form Name..."
                  value={formData?.title}
                  onChange={onChangeForm("title")}
                />
                {formData?.errors?.title && (
                  <span className="error-msg">{formData?.errors?.title}</span>
                )}
              </div>
              <div className="add-form-field">
                <label className="form-label">Description</label>
                <div className="description-field">
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder="Type in some description..."
                    value={formData?.description}
                    onChange={onChangeForm("description")}
                  />
                  <p className="count-number">
                    <span>
                      {formData?.description ? formData?.description.length : 0}
                    </span>
                    /150
                  </p>
                </div>
                {formData?.errors?.description && (
                  <span className="error-msg">
                    {formData?.errors?.description}
                  </span>
                )}
              </div>
              <div className="form-detail-footer">
                <div className="form-text">
                  <span className="upload-icon-img">
                    <figure>
                      <img src={Upload} alt="Icon" />
                    </figure>
                  </span>
                  <a
                    href={formData?.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {detail?.title}
                  </a>
                </div>
                <div className="replace-btn">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleReplaceClickOpen}
                  >
                    Replace
                  </Button>
                </div>
              </div>
            </div>
            <div className="replace-alert">
              <ReplaceFormDialog
                open={openReplace}
                handleClose={handleReplaceClose}
                onChangeFile={onChangeFile}
                formData={formData}
                handleFileSubmit={handleFileSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.formInfo?.current,
});
const mapDispatch = (dispatch) => ({
  getFormDetail: (data) => dispatch(getFormDetail(data)),
  editFormDetails: (data) => dispatch(editFormDetails(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
});
export default connect(mapState, mapDispatch)(ActiveFormsEdit);
