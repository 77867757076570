import { Route, Redirect } from "react-router-dom";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class PrivateRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;
    let isLoggedIn = localStorage.getItem("token") ? true : false;

    return (
      <Route
        {...rest}
        render={(props) => {
          return isLoggedIn ? <Component {...props} /> : <Redirect to="/" />;
        }}
      />
    );
  }
}
PrivateRoute.propTypes = {
  component: PropTypes.any,
  isLoggedIn: PropTypes.any,
};
const mapState = (state) => {
  return {
    isLoggedIn: state?.authInfo?.auth?.token || false,
  };
};

export default connect(mapState, null)(PrivateRoute);
