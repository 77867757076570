import { GET_FORM_DETAIL } from "store/types";

const initialState = {
  current: null,
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FORM_DETAIL:
      state.current = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default formReducer;
