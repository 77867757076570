import * as React from "react";
import { LeftSidebar, HeaderSection, Confirmation } from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams, useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Upload from "assets/images/upload.png";
import Button from "@mui/material/Button";
import "./index.css";
import { connect } from "react-redux";
import { getFormDetail, restoreForm } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";

const ArchivedFormsDetail = (props) => {
  const { getFormDetail, showLoader, detail, restoreForm } = props;
  const { id } = useParams();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState({
    confirm: 0,
  });

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { _id: id };
    const params = convertObjToStringParams(apiData);
    getFormDetail({ params });
  };

  const handleChangeRadio = (e) => {
    const newFormData = { ...filter };

    newFormData.confirm = 1;
    setFilter(newFormData);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFilter({
      confirm: 0,
    });

    setOpen(false);
  };

  const handleClickYes = () => {
    if (filter?.confirm === 0) return false;

    restoreForm({ _id: id }).then((resp) => {
      if (resp) {
        setOpen(false);
        history.goBack();
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <LeftSidebar />
        <HeaderSection />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <div className="back-arrow" onClick={() => history.goBack()}>
                  <ArrowBackIosNewIcon />
                  Back
                </div>
                <h1 className="title">{detail?.title}</h1>
              </div>
            </div>
            <div className="archived-msg-section">
              <p className="archiver-msg-info">This form is archived.</p>
              <div className="archived-replace-btn">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Unarchive Form
                </Button>
              </div>
            </div>
            <div className="form-detail-inner">
              <div className="add-form-field">
                <label className="form-label">Form Name</label>
                <TextField
                  //label="Title"
                  variant="outlined"
                  placeholder="Type in Form Name..."
                  value={detail?.title}
                  disabled
                />
                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="add-form-field">
                <label className="form-label">Description</label>
                <TextField
                  id="outlined-multiline-static"
                  //label="Description"
                  value={detail?.description}
                  multiline
                  rows={4}
                  placeholder="Type in some description..."
                  disabled
                />
                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="form-detail-footer">
                <div className="form-text">
                  <span className="upload-icon-img">
                    <figure>
                      <img src={Upload} alt="Icon" />
                    </figure>
                  </span>
                  <a
                    href={detail?.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {detail?.title}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title="Unarchive Form"
        description={` ${detail?.title} will be moved to “Active Forms”. Users will be able to access this form after unarchiving.`}
        open={open}
        handleClose={handleClose}
        handleClickYes={handleClickYes}
        unarchieve={true}
        confirm={filter?.confirm}
        handleChangeRadio={handleChangeRadio}
      />
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.formInfo?.current,
});
const mapDispatch = (dispatch) => ({
  getFormDetail: (data) => dispatch(getFormDetail(data)),
  restoreForm: (data) => dispatch(restoreForm(data)),
});
export default connect(mapState, mapDispatch)(ArchivedFormsDetail);
