import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";
const Loader = () => {
  return (
    <div className="loader-wrap">
      <div className="loader-inner">
        <CircularProgress className="white-color" />
      </div>
    </div>
  );
};

export default Loader;
