import * as React from "react";

const TermsConditions = () => {
  return (
    <div className="policy-wrapper">
      <div className="policy-inner">
        <h2 className="policy-subtitle">Actsafe Terms of Services</h2>
        <p className="para">Effective date: Mar 25, 2020</p>
        <p className="para">Last revised: Mar 25, 2021</p>
        <p>
          Privacy and data protection are important to the Actsafe community.
          Transparency is, too. We’ve done our best to cut through Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Ut semper tortor lectus,
          vel efficitur velit dignissim sed. Donec auctor metus in urna cursus,
          eu volutpat tortor convallis. Proin eleifend quam justo, in tincidunt
          lacus porttitor ac. Pellentesque quis laoreet dui, a facilisis justo.
          Aenean non interdum orci. Sed tempor erat enim, sed finibus eros
          pharetra quis. Morbi vel ex at justo pulvinar volutpat. Praesent eget
          odio nisi. Maecenas id ipsum pulvinar, cursus lacus et, consequat
          lacus. Integer tincidunt vulputate tortor, eget efficitur neque
          vulputate quis. Praesent ac sapien cursus, efficitur orci vel,
          ultricies turpis. Etiam a risus rhoncus, interdum lorem non, semper
          nunc. Vestibulum ornare ultricies dui, sit amet ornare dui blandit
          fringilla. Nam eros velit, semper et tristique in, vulputate et risus.
          Pellentesque erat mi, volutpat nec porttitor quis, fringilla quis
          ante. Nulla pharetra augue id dolor imperdiet, eu convallis purus
          facilisis.
        </p>
        <p>
          Nulla mollis eu nibh quis ultrices. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Cras
          facilisis eros ex, eget feugiat arcu auctor et. Donec euismod tellus
          sed dui feugiat, ut vehicula sapien vehicula. Etiam nec justo eget
          nisi feugiat commodo. Ut ultricies elit at pretium porttitor. Sed
          maximus est quis nulla maximus, id posuere dui varius. Donec a lacinia
          urna. Fusce commodo sapien turpis, eget efficitur diam aliquam ut.
          Proin faucibus sapien libero, a dictum risus porttitor eget. Maecenas
          ultrices, nunc feugiat sodales placerat, nulla lacus interdum velit,
          eu consectetur libero nunc ultrices sem. Maecenas molestie eros vitae
          dolor eleifend, eget ultricies nunc feugiat. Cras malesuada lacinia
          faucibus. Curabitur faucibus tellus a dui cursus, in fermentum leo
          aliquet. Donec eget elementum nulla.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
