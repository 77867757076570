import React from "react";
import Logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const ForgotPassword = () => {
  return (
    <div className="account-wrapper">
      <div className="account-inner">
        <div className="login-section">
          <div className="logo">
            <Link to="/">
              <figure>
                <img src={Logo} alt="Logo" />
              </figure>
            </Link>
          </div>
          <h1 className="page-title">Forgot Password</h1>
          <p className="page-description">
            We’ll help you reset it and get back on track
          </p>
          <div className="form-wrapper">
            <form autoComplete="off">
              <div className="input-row">
                <TextField
                  type="text"
                  id="email"
                  label="Email"
                  variant="outlined"
                />
                {/* <span className="error-msg">Static Content Error</span> */}
              </div>
              <div className="btn-wrap login-btn">
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
