import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { getUserDetail } from "store/actions";
import { connect } from "react-redux";

const HeaderSection = (props) => {
  const { getUserDetail, myInfo } = props;

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    getUserDetail();
  };
  return (
    <header className="header">
      <div className="header-inner">
        <div className="header-left">
          <Link to="/profile">Hi {myInfo?.firstName},</Link>
          <h2 className="header-title">Welcome</h2>
        </div>
      </div>
    </header>
  );
};
const mapState = (state) => ({
  myInfo: state?.authInfo?.my_detail,
});
const mapDispatch = (dispatch) => ({
  getUserDetail: () => dispatch(getUserDetail()),
});

export default connect(mapState, mapDispatch)(HeaderSection);
